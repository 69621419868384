<template>

    <article class="productCard" :class="cssClasses">

        <header class="productHeader">


            <div class="productTitleLink" v-if="optionCard">
                <h2
                        class="productTitle"
                        v-text="product.name"
                        v-if="product.name"
                ></h2>

<!--                <stock-badge-->
<!--                        :has-stock="inStock"-->
<!--                        :price="fromPrice"-->
<!--                        :show-price="true"-->
<!--                        :currency-symbol="'£'"-->
<!--                        :small="true"-->
<!--                ></stock-badge>-->

            </div>

            <a :href="productUrl" class="productTitleLink" v-else>
                <h2
                        class="productTitle"
                        v-text="product.name"
                        v-if="product.name"
                ></h2>
            </a>

            <h4 class="categoryTitle" v-text="category.name" v-if="category.name"></h4>

<!--            <div class="productDescription" v-if="productDescription">-->
<!--                <p v-html="productDescription"></p>-->
<!--            </div> &lt;!&ndash; .productDescription &ndash;&gt;-->

        </header>

        <section class="productImageSection">


            <div class="productCardImage" v-if="slideshow">

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="true"
                        :show-arrows="true"
                        :show-thumbs="false"
                        :allow-zoom="false"
                        :brand="(product.brand !== null && product.brand?.image_url) ? product.brand.image_url : false"
                ></product-images>

            </div> <!-- .productCardImage -->

            <a :href="productUrl" class="productCardImage" v-else>

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="false"
                        :show-arrows="false"
                        :show-thumbs="false"
                        :allow-zoom="false"
                        :brand="(product.brand !== null && product.brand?.image_url) ? product.brand.image_url : false"
                ></product-images>

            </a>

        </section>

        <footer class="productFooter optionsCardFooter" :class="[optionQuantityAdjust ? 'optionsFooterQuantityAdjust' : '']" v-if="optionCard">

            <div class="footerSizes">


                <div
                        class="sizeOptions"
                        :class="[previewOnly ? 'fullWidth' : '']"

                >

                    <div
                            class="sizeOption sizeOptionStrength"
                            v-if="optionSize.strength?.length && optionShowSize && !optionQuantityAdjust"
                    >

                        <span class="sizeTitle">Strength</span>
                        <span class="sizeValue" v-html="optionSize.strength"></span>

                    </div> <!-- .sizeOption -->

                    <div
                            class="sizeOption sizeOptionStrength"
                            v-if="optionSize.size?.length && optionShowSize && !optionQuantityAdjust"
                    >

                        <span class="sizeTitle">Size</span>
                        <span class="sizeValue" v-html="optionSize.size"></span>

                    </div> <!-- .sizeOption -->

                    <div
                            class="sizeOption sizeOptionStrength"
                    >

                        <span class="sizeTitle" v-html="(previewOnly) ? 'Quantity' : 'QTY'"></span>

                        <span class="sizeValue" v-html="optionSize?.quantity || 1" v-if="optionSize"></span>
                        <span class="sizeValue" v-html="product.quantity" v-else></span>

                    </div> <!-- .sizeOption -->



<!--                    <div class="quantityAdjustActions" v-if="optionQuantityAdjust">-->

<!--                        <button-->
<!--                                type="button"-->
<!--                                class="button buttonOutlined noMargin"-->
<!--                                @click.prevent="quantityAdjustVisible = true"-->
<!--                                :disabled="!optionEnabled"-->
<!--                        >-->
<!--                            Adjust quantity-->
<!--                        </button>-->

<!--                        <travel-quantity-adjust-->
<!--                                :product="product"-->
<!--                                :set-quantity="optionSize.quantity"-->
<!--                                :is-visible="quantityAdjustVisible"-->
<!--                                @quantity-updated="updateQuantitySelected"-->
<!--                                @quantity-closed="updateQuantitySelected"-->
<!--                                @quantity-cancelled="quantityAdjustVisible = false"-->
<!--                        />-->


<!--                    </div> &lt;!&ndash; .quantityAdjustActions &ndash;&gt;-->

                </div> <!-- .sizeOptions -->

            </div> <!-- .footerSizes -->

            <div class="footerButtons" v-if="!previewOnly">

                    <a
                            href="#select-option"
                            v-html="inStock ? optionSelectButtonText : 'Out of stock'"
                            class="button noMargin"
                            @click.prevent="triggerSelected()"
                            :disabled="!optionEnabled"
                            v-if="!optionSelected"
                    ></a>

                    <a
                            href="#deselectselect-option"
                            v-html="optionDeselectButtonText"
                            class="button buttonOptionSelected noMargin"
                            @click.prevent="triggerDeselected()"
                            :disabled="!optionEnabled"
                            v-else
                    ></a>



            </div>

        </footer>

        <footer class="productFooter" v-else>

<!--            <stock-badge-->
<!--                    :has-stock="inStock"-->
<!--                    :price="fromPrice"-->
<!--                    :show-price="true"-->
<!--                    :currency-symbol="'£'"-->
<!--                    :small="true"-->
<!--                    v-if="((product?.sizes?.length < 2) || !basketButtonData.add_direct_to_basket) && !minimal"-->
<!--            ></stock-badge>-->

            <div class="footerBottom">

                <add-to-basket
                        :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                        :basket-button-data="basketButtonData"
                        :browse-page="true"
                        :collapse-size="true"
                        :product="product"
                        v-if="!previewOnly"
                ></add-to-basket>

                <a
                        :href="product.url"
                        v-html="viewProductText"
                        title="View product"
                        class="button noMargin"
                        v-else
                ></a>

            </div> <!-- .footerBottom -->


        </footer>

    </article>

</template>

<script>

import _ from 'lodash';
import travelQuantityAdjust from './partials/travel_quantity_adjust';
import addToBasket from './partials/add_to_basket';


export default {
    name: "product-card",
    components: {
        addToBasket,
        travelQuantityAdjust
    },
    props: {
        useTrustpilot: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        optionCard: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        optionSize: {
            type: [Object, Boolean],
            default (rawProps) {
                return false;
            }
        },
        optionShowSize: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        optionQuantityAdjust: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        optionEnabled: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        optionSelectButtonText: {
            type: [String],
            default (rawProps) {
                return 'Select treatment';
            }
        },
        optionDeselectButtonText: {
            type: [String],
            default (rawProps) {
                return 'Remove selection';
            }
        },
        classes: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        //Show a card with minimal options
        previewOnly: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        //If true, price will be hidden and button will become a link
        minimal: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        fromPrice: {
            type: [Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        lazyLoad: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        slideshow: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        showDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    created() {

        if(this.optionCard && this.product) {
            if(_.isUndefined(this.product.quantity)) {
                this.product.quantity = 1;
            }
        }

        if(!_.isUndefined(window.globalConfig)) {
            this.trustpilotBusinessID = this.getConfigOrFalse('trustpilotBusinessID');
            this.trustpilotTemplateID = this.getConfigOrFalse('trustpilotTemplateID');
            this.trustpilotLink = this.getConfigOrFalse('trustpilotLink');
            this.trustpilotStarColor = this.getConfigOrFalse('trustpilotStarColor');
            this.trustpilotTextColor = this.getConfigOrFalse('trustpilotTextColor');
        }

    },
    mounted() {

        const vm = this;

        if(vm.useTrustpilot) {

            vm.interval = setInterval(() => {

                if (!_.isUndefined(window.Trustpilot)) {
                    clearInterval(vm.interval);
                    let elem = vm.$refs.trustbox;

                    if(elem) {
                        window.Trustpilot.loadFromElement(elem);
                        vm.trustpilotReady = true;
                    }
                }

            }, 1000);

        }

    },
    data() {
        return {
            maxDescriptionLength: 100,
            maxOptionDescriptionLength: 250,
            viewProductText: 'View product',
            fallbackImagePath: '/sites/default/images/no-image-items-thumb.png',
            quantityAdjustVisible: false,
            interval: null,
            trustpilotReady: false,
            trustpilotBusinessID: false,
            trustpilotTemplateID: '',
            trustpilotLink: '',
            trustpilotStarColor: '',
            trustpilotTextColor: ''
        }
    },
    computed: {

        productDescription() {

            if(!this.showDescription) return false;

            let text = '';

            if(this.product?.custom?.description__product_card) {
                text = this.product.custom.description__product_card;
            }else if(this.product?.description) {
                text = this.product.description;
            }


            if(text.length) {

                let maxLength = this.maxDescriptionLength;

                if(this.optionCard) {
                    maxLength = this.maxOptionDescriptionLength;
                }

                let append = '';

                if(text.length > maxLength) {
                    append = '...';
                }

                return text
                        .replace(/(<([^>]+)>)/gi, "")
                        .substring(0,maxLength)
                        + append;

            }

            return false;
        },

        productImageSrc() {

            if(!this.productImage) {
                return this.fallbackImagePath;
            }

            return this.productImage;
        },

        optionSelected() {

            if(this.optionSize) {
                return this.optionSize.selected;
            }else{
                return this.product.selected;
            }
        },

        cssClasses() {

            let classes = [...[], ...this.classes];

            if(this.optionCard && ( classes.indexOf('optionCard') < 0) ) {
                classes.push('optionCard');
            }

            if((this.optionSize.selected || this.product.selected) && (classes.indexOf('optionSelected') < 0) ) {
                classes.push('optionSelected');
            }

            return classes;
        }

    },

    methods: {

        triggerSelected() {

            let id = 0;

            if(!_.isUndefined(this.product.id)) {
                id = this.product.id;
            }

            if(!_.isUndefined(this.optionSize.id)) {
                id = this.optionSize.id;
            }


            this.$emit('option-selected', this.product.id, id);
        },

        triggerDeselected() {

            let id = 0;

            if(!_.isUndefined(this.product.id)) {
                id = this.product.id;
            }

            if(!_.isUndefined(this.optionSize.id)) {
                id = this.optionSize.id;
            }

            this.$emit('option-deselected', this.product.id, id);
        },

        updateQuantitySelected(qty) {


            if(!_.isUndefined(qty)) {

                qty = parseInt(qty);

                if(!isNaN(qty)) {

                    if(this.optionSize) {
                        this.optionSize.quantity = (qty > 0) ? qty : this.product.minimum_quantity;
                    }

                    this.product.quantity = qty;
                }
            }

            this.quantityAdjustVisible = false;

            /*
            if(this.optionSize) {
                //if(this.optionSize.selected) {
                    this.triggerSelected();
                //}
            }else{

                //if(this.product.selected) {
                    this.triggerSelected();
                //}
            }
             */

            this.triggerSelected();


        },

        getConfigOrFalse(key) {

            if(!_.isUndefined(window.globalConfig)) {

                if(!_.isUndefined(window.globalConfig[key])) {
                    return window.globalConfig[key];
                }

            }

            return false;

        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productCard {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        text-align: center;
        height: 100%;
        position: relative;


        .innerImage {
            order: 1;
            padding: 0;
        }

        .productHeader {
            order: 2;
            padding: 15px 15px 0 15px;
            text-align: inherit;
        }

        .trustpilotCardWrapper {
            order: 3;
            width: 100%;
            display: flex;
            justify-content: center;
            opacity: 0;
            transition: opacity 200ms linear;

            .trustpilot-widget {
                width: 80px;
                height: 16px;
                overflow: hidden;
            }

            &.trustpilotReady {
                opacity: 1;
                transition: opacity 200ms linear;
            }

        }


        .productFooter {
            order: 4;
            padding: 15px;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            margin-top: auto;


            .footerBottom {
                //padding: 25px 0 0;
                margin-top: 0;
            }
        }

        .productTitleLink {
            color: @text_color;
            text-decoration: none;

            .productTitle {
                color: @text_color;
                transition: color 200ms linear;
            }

        }

        a.productTitleLink {

            &:hover, &:active, &:focus {
                .productTitle {
                    color: @highlight_color;
                    transition: color 200ms linear;
                }
            }

        }

        .productTitle {
            .rems(14);
            line-height: 22px;
            font-weight: 400;
            margin: 0;
        }

        .categoryTitle {
            .rems(14);
            line-height: 1.2em;
            font-weight: 700;
            display: none;
            margin: 0;
            padding: 12px 0 0;
        }

        .productImageSection {

            max-width: 100%;

            .productImage {

                &, &.largeImages {

                    padding: 8px;

                    .mainImage {
                        border: none;
                        border-radius: @border_radius @border_radius 0 0;
                        overflow: hidden;
                        background: #fff;
                        padding: 0;
                    }

                }


            }

        }

        .productContent {
            padding: 15px 15px 0;

            .productTitle {
                .rems(16);
                line-height: 20px;
                font-weight: 700;
                margin: 0;
            }
        }

        .productCta {
            padding: 15px 15px 8px 15px;
            margin-top: auto;

            .button {
                margin: 15px 0 0;
                width: 100%;
            }

        }

        .basketActions {

            & > .addToBagButton {
                .rems(12);
                line-height: 12px;
            }

            .basketButtonRow {
                flex-flow: row wrap;
                justify-content: space-between;


                &:before, &:after {
                    display: none;
                }

                .rowInput {
                    flex: 0 0 65px;

                    .mNumberInput input {
                        min-height: 41px !important;
                        height: 41px !important;
                    }
                }

                .rowPrice {
                    flex: 1 0;
                    min-height: 40px;
                    padding: 0 15px;
                    text-align: center;
                    justify-content: center;
                }

                .rowButton {
                    padding-left: 0!important;

                    .addToBagButton {
                        padding: 9px 12px 7px;
                        .rems(12);
                        line-height: 12px;
                        text-align: center;
                    }
                }
            }
        }

        .productSizes {
            background: #F5ECEE;
            border-radius: @border_radius;
            padding: 8px;
            margin: 0 0 16px;
            text-align: left;
            
            .toggleSizes {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                color: @text_color;
                cursor: pointer;
                .rems(12);
                line-height: 12px;
                
                span {
                    margin: 2px 0 0;
                }
                
                .svgIcon {
                    width: 12px;
                    height: unset;
                    
                    svg {
                        height: 10px;
                        width: 10px;
                    }
                }
            }
            
            .multiSize, .singleSize {
                padding: 16px 8px 8px;
            }
            
            .multiSize {
                flex-flow: column nowrap;
                gap: 16px;
            }

            .productSize {
                flex-flow: column nowrap;
                align-items: unset;
                gap: 3px;
            }


            .chosen-container {

                .chosen-single {

                    padding: 15px 40px 15px 13px;

                    span {
                        .rems(12);
                        line-height: 1em;
                        letter-spacing: 0.1em;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    & > div {
                        width: 32px;

                        b {

                            margin-top: 1px;

                            &:after {
                                color: #1D1D1D;
                            }
                        }
                    }

                }

                .chosen-results {
                    li {
                        .rems(12);
                        line-height: 1em;
                        letter-spacing: 0.1em;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 13px 15px;
                    }
                }

            }

        }

        .button {
            &.noMargin {
                margin: 0;
            }
        }

        &.largeCard {

            border-radius: 20px;

            .productHeader {
                text-align: inherit;
                padding: 20px 10px 15px;

                .landingItemsWrap & {

                    @media @mobile {
                        padding: 15px 15px 12px 15px;
                    }
                }
            }


            .productTitle {
                .rems(18);
                line-height: 1.2em;

                @media @flyout {
                    .rems(16);
                }
            }

            .productImage {
                padding: 25px;
                background: #fff;
                border-radius: 15px;

                .landingItemsWrap & {

                    @media @mobile {
                        padding: 15px;
                    }
                }
            }

            .productFooter {
                text-align: inherit;
                padding: 0 10px 10px;

                .landingItemsWrap & {

                    @media @mobile {
                        padding: 0 15px 25px 15px;
                    }
                }
            }

            .footerBottom {
                text-align: inherit;
                //padding: 25px 0 0;

                .landingItemsWrap & {

                    @media @mobile {
                        padding: 15px 0 0;
                    }
                }
            }


        }

        &.optionCard {

            display: grid;
            grid-gap: 0;
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:
            "image content content content"
            "footer footer footer footer";
            text-align: left;
            padding: 0;
            border: 1.5px solid @product_card_light_color;
            overflow: hidden;
            transition: border-color 200ms linear;

            @media @mobile {
                display: flex;
                position: relative;
            }

            &.optionSelected {
                border-color: @primary_color;
                transition: border-color 200ms linear;
            }

            .productImageSection {
                order: 1;
                grid-area: image;

                @media @mobile {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .productHeader {
                order: 2;
                grid-area: content;
                padding: 25px 25px 19px 25px;

                @media @mobile {
                    flex: 0 0 100%;
                    padding: 24px 25px 19px 25px;
                }
            }

            .productTitleLink {
                display: flex;

                @media all and (max-width: 750px) {
                    flex-wrap: wrap;
                }

                .productTitle {
                    flex: 1 1 100%;
                    padding: 0 25px 0 0;

                    @media all and (max-width: 750px) {
                        flex: 0 0 100%;
                        padding: 0;
                    }

                    @media @mobile {
                        padding: 0 0 0 90px;
                        min-height: 66px;
                    }
                }

                .stockWrap {
                    flex: 1 1 auto;
                    white-space: nowrap;
                    text-align: center;
                    padding: 0 0 15px;

                    @media all and (max-width: 750px) {
                        flex: 0 0 100%;
                        padding: 8px 0 0;
                    }

                    @media @mobile {
                        flex: 0 0 100%;
                        padding: 12px 0 0 0;
                        display: flex;
                        justify-content: center;
                    }

                }
            }

            .productDescription {
                padding: 16px 0 0;
                .rems(14);
                line-height: 1.2em;
                font-weight: 500;

                @media @mobile {
                    text-align: center;
                }
            }

            .productFooter {
                order: 3;
                grid-area: footer;
                display: flex;
                flex-direction: row;
                padding: 25px;
                background: @product_card_light_color;

                @media all and (max-width: 750px) {
                    flex-wrap: wrap;
                }

                &.optionsFooterQuantityAdjust {
                    .footerSizes {

                        @media all and (max-width: 750px) {
                            flex-wrap: wrap;
                            min-width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                        }

                    }

                    .footerButtons {
                        padding-left: 20px;

                        @media all and (max-width: 750px) {
                            padding: 10px 0 0 0;
                        }

                        .button {
                            @media all and (max-width: 750px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .footerSizes {
                flex: 1 1 130%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

            }

            .sizeOptions {
                width: 100%;
                min-height: 61px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                background: #fff;
                text-transform: uppercase;
                padding: 0 12.5px;

                @media all and (max-width: 750px) {
                    padding: 0;
                    width: 100%;
                    flex: 0 0 100%;
                }

                &.fullWidth {
                    width: 100%;
                    flex: 0 0 100%;
                }

                .sizeOption {
                    font-weight: 700;
                    flex: 1 1 auto;
                    padding: 15px 12.5px;

                }

                .sizeTitle {
                    .rems(12);
                    line-height: 12px;
                    letter-spacing: 0.1em;
                    display: block;
                    width: 100%;
                    margin: 0 0 5px;
                }

                .sizeValue {
                    .rems(14);
                    line-height: 14px;
                    letter-spacing: 0.1em;
                    display: block;
                    width: 100%;

                    @media @mobile {
                        .rems(12);
                        line-height: 12px;
                    }
                }
            }

            .quantityAdjustActions {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 100%;
                padding: 8px 12.5px 8px 12.5px;
            }

            .footerButtons {
                flex: 1 1 100%;
                padding: 0 10px 0 36px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @media all and (max-width: 750px) {
                    padding: 10px 0 0 0;
                }
            }

            .button {
                margin: 0;
                width: 100%;
                max-width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }


        }

    }


</style>
