var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "browsePage" },
    [
      _vm.page
        ? [
            _c("page-title", {
              attrs: {
                title: _vm.page.title,
                content: _vm.page.title_content,
                "css-class": "browseTitle",
                brand:
                  typeof _vm.page.brand_data !== "undefined" &&
                  _vm.page.brand_data !== null
                    ? _vm.page.brand_data
                    : false,
              },
            }),
            _vm._v(" "),
            _vm.page.brand_data
              ? [
                  _vm.page.brand_data.additional_info !== null &&
                  _vm.page.brand_data.additional_info !== ""
                    ? _c("div", { staticClass: "heroAdvice" }, [
                        _c("div", { staticClass: "wrapper" }, [
                          _c("div", { staticClass: "heroAdvice__inner" }, [
                            _c("div", { staticClass: "heroAdvice__icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 512 512",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      d: "M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "heroAdvice__content",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.page.brand_data.additional_info
                                  ),
                                },
                              },
                              [
                                _c("h5", [_vm._v("Get professional support")]),
                                _vm._v(" "),
                                _vm._m(0),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("product-filter", {
            attrs: {
              items: _vm.products,
              filters: _vm.filters,
              loading: _vm.loading,
            },
            on: { "update-ajax": _vm.updateAjax },
          }),
          _vm._v(" "),
          _c("product-list", {
            attrs: {
              loading: _vm.loading,
              products: _vm.products,
              "parent-class": ["browseProducts"],
              "list-class": ["itemsBrowse"],
            },
          }),
          _vm._v(" "),
          _c("pagination", {
            attrs: { pagination: _vm.pagination },
            on: { "update-ajax": _vm.updateAjax },
          }),
          _vm._v(" "),
          _c("loading-spinner", {
            attrs: { loading: _vm.loading, "loading-text": "Loading Products" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Speak to a professional dermatological skincare advisor to find out what products could best help you. You can call "
      ),
      _c("a", { attrs: { href: "tel:0161 503 3731" } }, [
        _vm._v("0161 503 3731"),
      ]),
      _vm._v(" or email "),
      _c("a", { attrs: { href: "mailto:info@oushkpharmacy.com" } }, [
        _vm._v("info@oushkpharmacy.com"),
      ]),
      _vm._v(" to find out more."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }