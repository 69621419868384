<template>

    <section class="ordersSlider" v-if="hasBenefits(orders)">

        <Splide
            :options="sliderOptions"
            ref="ordersSlider"
        >

            <SplideSlide
                v-for="(order, index) in orders"
                :key="'order' + index"
            >
                <div class="orderSlideWrap">

                    <div class="slideHeader">

                        <div class="slideDate">
                            <span class="headTitle">Order Placed</span>
                            <span class="headValue" v-html="order.date"></span>
                        </div> <!-- .slideDate -->

                        <div class="slideDeliveryDate"
                             v-if="typeof order.delivery_date !== 'undefined' && order.delivery_date !== null && order.delivery_date !== '0000-00-00'">
                            <span class="headTitle">Delivery Date</span>
                            <span class="headValue" v-html="formatDate(order.delivery_date)"></span>
                        </div> <!-- .slideDeliveryDate -->

                        <div class="slidePrice">
                            <span class="headTitle">Total</span>
                            <span class="headValue"><multi-currency-price :price="order.cost_total"/></span>
                        </div> <!--.slidePrice -->

                        <div class="slideButton">
                            <a :href="'/members/view_order.php?id=' + order.id"
                               class="button buttonOutlined">
                                View order
                                <i class="svgIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 9" fill="none"><path d="M10 4.49438L5.54444 9L3.86667 9L7.72222 5.10112L4.64936e-08 5.10112L6.08303e-08 3.89888L7.73333 3.89888L3.87778 4.6242e-08L5.55556 6.62493e-08L10 4.49438Z" fill="currentColor"/></svg>
                                </i>
                            </a>
                        </div> <!-- .slideButton -->

                    </div> <!--.slideHeader -->

                    <div class="slideBody">
                        <div v-for="(item, index) in order.items.slice(0, 3)"
                             class="slideItem"
                             :key="'item' + index">

                            <div class="imageWrap">
                                <img v-if="item.image !== null && item.image !== ''"
                                     :src="item.image"
                                     :alt="item.item_name"/>
                                <img v-else
                                     src="/sites/default/images/no-image.jpg"
                                     :alt="item.item_name" />
                            </div> <!-- .imageWrap -->

                            <div class="itemDetails">
                                <div class="itemTitle" v-html="'Product: ' + item.item_name"></div>
                            </div> <!-- .itemDetails -->

                            <div class="itemPrice">
                                <span class="itemQty" v-html="'Qty: ' + item.quantity"></span>
                                <multi-currency-price :price="item.price_paid"/>
                            </div> <!-- .itemPrice -->

                        </div> <!-- .slideItem -->

                    </div> <!-- .slideBody -->

                </div> <!-- .orderSlideWrap -->

            </SplideSlide>

        </Splide>

    </section> <!-- .ordersSlider -->

</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: "OrdersSlider",
    components: {
        Splide,
        SplideSlide
    },
    props: {
        orders: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        imagePath: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            sliderOptions: {
                gap: 24,
                rewind: false,
                pagination: false,
                arrows: true,
                fixedWidth: 633,
                perMove: 1,
                mediaQuery: 'max',
                breakpoints : {
                    680: {
                        fixedWidth: 300,
                        perMove: 1,
                        gap: 16
                    },
                }
            },
        }
    },

    created() {

    },
    mounted() {

    },
    computed: {


    },
    methods: {

        hasBenefits(orders) {

            if(typeof orders != 'undefined') {
                return orders.length;
            }

            return false;

        },

        formatDate(date) {
            return new Date(date).toLocaleDateString();
        }

    }
}
</script>

<style lang="less">

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';
@import '@splidejs/splide/dist/css/splide.min.css';


.ordersSlider {
    width: 100%;
    position: relative;
    float: none;
    clear: both;
    padding: 0 0 75px;


    .orderSlideWrap {
        width: 100%;
        max-width: 633px;
        background: #F7F0EE;
        border-radius: 15px;
        height: 100%;
        padding: 50px;

        .slideHeader {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 37px;
            margin: 0 0 35px;

            .headTitle {
                display: block;
                .rems(10);
                line-height: 12px;
                margin: 0 0 10px;
            }

            .headValue {
                display: block;
                .rems(18);
                line-height: 25px;
                color: @primary_color;
            }
        }

        .slideBody {
            display: flex;
            flex-flow: column nowrap;
            gap: 15px;
        }

        .slideButton {
            flex: 1 0;
            text-align: right;

            .button {
                margin: 0;
            }
        }

        @media all and (max-width: 680px) {
            padding: 30px 25px;

            .slideHeader {
                flex-flow: row wrap;
                justify-content: space-between;
                gap: 20px;
            }

            .slideButton {
                flex: 1 0 100%;

                .button {
                    width: 100%;
                }
            }
        }

        .slideItem {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 25px;
            background: rgba(255, 255, 255, 0.35);
            padding: 0 36px 0 0;

            .imageWrap {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: #ffffff;
                aspect-ratio: 1 / 1;
                height: 101px;

                > img {
                    max-height: 65px;
                    max-width: 65px;
                    height: auto;
                    width: auto;
                }
            }

            .itemDetails {
                flex: 1 0;
                .rems(12);
                line-height: 14px;
                display: flex;
                flex-flow: column nowrap;
                gap: 12px;
            }

            .itemPrice {
                flex: 0 0;
                text-align: right;

                .itemQty {
                    display: block;
                    .rems(10);
                    line-height: 12px;
                    letter-spacing: 0.2px;
                    margin: 0 0 10px;
                }

                .multiPrice {
                    .rems(14);
                    line-height: 18px;
                }
            }

            @media all and (max-width: 680px) {
                flex-flow: column nowrap;
                padding: 0;
                text-align: center;

                .imageWrap {
                    width: 100%;
                    height: unset;

                    > img {
                        max-width: 200px;
                        max-height: 200px;
                    }
                }

                .itemDetails {
                    padding: 0 20px;
                }

                .itemPrice {
                    padding: 0 20px 20px;
                }
            }
        }
    }

    .splide__track {
        overflow: visible;
    }

    .splide__arrows {
        //position: absolute;
        //bottom: auto;
        //top: 100%;
        //left: 0;
        //right: auto;
        //display: flex;
        //justify-content: flex-start;
        //align-items: center;
        //padding: 24px 0 0;
        //
        //@media @mobile {
        //    display: none;
        //}
    }

    .splide__arrow {
        width: 48px;
        height: 48px;
        background: transparent;
        border-radius: 0;
        //position: relative;
        //top: auto;
        //right: auto;
        //bottom: auto;
        //left: auto;
        //transform: none;
        display: flex;
        align-items: center;
        opacity: 1;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;

        &:after {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        &.splide__arrow--prev {
            //justify-content: flex-start;
            left: -15px;
        }

        &.splide__arrow--next {
            //margin-left: 1px;
            //justify-content: flex-end;
            right: -15px;
        }

        &[disabled] {
            opacity: 0;
            pointer-events: none;
        }
    }


}
</style>