<template>

    <div 
            id="heroTitle"
            class="titleBanner standard"
            :class="cssClass"
            v-if="title"
    >
        <div class="wrapper">
            <div class="innerTitleBanner">
                <div class="bannerContentWrap">
                    <div class="innerContentWrapper">
                        <div class="titleWrap">
                            <div class="innerText">
                                <div class="brandLogoWrap" v-if="brand.logo_url">
                                    <img :src="brand.logo_url" :alt="brand.name"/>
                                </div> <!-- .brandLogoWrap -->
                                
                                <h1 v-html="title"></h1>

                                <div class="titleContent wysiwyg" v-if="content" v-html="content"></div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'pageTitle',
    props: {
        title: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        content: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        cssClass: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        brand: {
            type: [Object, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
    
    .titleBanner {
        
        &.browseTitle {
            
            min-height: 24px;
            padding: 68px 0 56px;

            .innerTitleBanner {
                
                .titleWrap {
                    
                    .innerText {

                        .brandLogoWrap {
                            text-align: center;
                            
                            img {
                                max-width: 140px;
                                height: auto;
                            }
                            
                            & + h1 {
                                position: absolute !important;
                                width: 1px !important;
                                height: 1px !important;
                                padding: 0 !important;
                                margin: -1px !important;
                                overflow: hidden !important;
                                clip: rect(0,0,0,0) !important;
                                white-space: nowrap !important;
                                border: 0 !important;
                            }
                        }
                        
                        h1 {
                            margin: 0;
                        }
                        
                    }
                    
                }
                
            }

        }
        
    }

</style>
