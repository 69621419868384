window.$ = window.jQuery = require('jquery');
require("moment");

require("../../../core/js/plugins/mtc_jquery_ui.js");
require("../../../core/js/plugins/mtc_jquery_ui_touch_punch.js");

import Vue from 'vue';
window.Vue = Vue;
require('vue-resource');


// Amends to vue resource for our archaic server setup
Vue.http.options.emulateJSON = true;
//Vue.http.interceptors.unshift(function(request, next) {
//    next(function(response) {
//        if(typeof response.headers['content-type'] != 'undefined') {
//            response.headers['Content-Type'] = response.headers['content-type'];
//        }
//    });
//});

import { config } from './config.js';
window.globalConfig = config;

import {EventBus} from "./event-bus";
window.EventBus = EventBus;

import {vueEventHub} from "./event-bus";
window.vueEventHub = vueEventHub;

//window.LazyLoad = require ("vanilla-lazyload");


import mtcMenu from './components/navigation/menu.vue';
Vue.component("mtc-menu", mtcMenu);

/*
* Font awesome
*/
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
library.add(faUpload, faCircleXmark, faXmark, faMagnifyingGlass, faTrash);
Vue.component('font-awesome-icon', FontAwesomeIcon);

//Slider
import { Splide, SplideSlide } from '@splidejs/vue-splide';
Vue.component('mtc-slider', Splide);
Vue.component('mtc-slide', SplideSlide);

/*
* Core
 */
require("../../../core/js/plugins/mtc_vue_directives.js");
require("../../../core/js/plugins/mtc_vue_filters.js");
require("../../../core/js/functions.js");
require("../../../core/js/plugins/mtc_cookies.js");
require("../../../core/js/plugins/mtc_chosen.js");
require("../../../core/admin/js/libs/tagit/js/tag-it.min.js");
require("../../../core/js/plugins/mtc_easing.js");
require("../../../core/js/plugins/mtc_hover_intent.js");
require("../../../core/js/plugins/mtc_mobile_menu.js");
require("../../../core/js/plugins/mtc_multi_currency.js");
require("../../../core/js/plugins/mtc_responsive_tables.js");
require("../../../core/js/plugins/mtc_number_arrows.js");
require("../../../core/js/plugins/mtc_tabs.js");
require("../../../core/js/plugins/mtc_waypoints.js");
require("../../../core/js/plugins/mtc_slick.js");
require("../../../core/js/site_scripts/mtc_show_hide_menus.js");
require("../../../core/js/plugins/mtc_zoom.js");


require("../../../cms/js/mtc_contact.js");
require("../../../cms/js/mtc_faq_section.js");

require("../../../modules/Members/js/mtc_members.js");

require("../../../plugins/FeaturedBrands/js/mtc_featured_brands.js");
//require("../../../plugins/NewsletterSignup/js/mtc_Newsletter.js");
//require("../../../plugins/ComplimentedItems/js/mtc_complimented_items.js");
require("../../../plugins/GoogleMaps/js/mtc_google_maps.js");
//require("../../../plugins/CustomersAlsoViewed/js/mtc_customers_also_viewed.js");
//require("../../../plugins/NHS/js/mtc_prescriptions.js");
require("../../../plugins/Slider/js/mtc_slider.js");
//require("../../../plugins/RecentlyViewed/js/mtc_recently_viewed.js");
//require("../../../plugins/TrustpilotAPI/js/mtc_trustpilot.js");
require("../../../plugins/Judopay/js/judopay.js");



require("../../../plugins/MolliePay/js/mtc_mollie_pay.js");


require("../../../shop/js/mtc_checkout.js");
require("../../../shop/js/mtc_checkout_mobile.js");
require("../../../shop/js/mtc_cross_sell_tabs.js");
require("../../../shop/js/mtc_checkout_stripe.js");
require("../../../shop/js/mtc_checkout_worldpay.js");
require("../../../shop/js/mtc_payment_button.js");



import mtcMinibasket from './components/shop/basket/mtc_minibasket';
Vue.component('mtc-minibasket', mtcMinibasket);

import loadingSpinner from './components/loading_spinner';
Vue.component("loading-spinner", loadingSpinner);

import productSidebar from "./components/shop/item/product_sidebar.vue";
Vue.component('product-sidebar', productSidebar);

import BrowsePage from "./components/shop/browse/browse_page.vue";
Vue.component('browse-page', BrowsePage);

import multiCurrencyPrice from "./components/multi_currency_price.vue";
Vue.component('multi-currency-price', multiCurrencyPrice);

import stockBadge from './components/shop/item/partials/stock_badge';
Vue.component("stock-badge", stockBadge);

import productCard from "./components/shop/item/product_card.vue";
Vue.component("product-card", productCard);

import productCardLarge from "./components/shop/item/product_card_large.vue";
Vue.component("product-card-large", productCardLarge);

import productImages from "./components/shop/item/partials/product_images";
Vue.component("product-images", productImages);

import categorySlider from "./components/shop/featured/category_slider";
Vue.component("category-slider", categorySlider);

import productSlider from "./components/shop/featured/product_slider";
Vue.component("product-slider", productSlider);

import categoryProducts from "./components/shop/browse/category_products";
Vue.component("category-products", categoryProducts);

import assessmentForm from "./components/assessment/assessment_form";
Vue.component("assessment-form", assessmentForm);

import gpSurgerySearch from './components/gp_surgery_search';
Vue.component("gp-surgery-search", gpSurgerySearch);

import BenefitsSlider from "./components/members/dashboard/benefits_slider";
Vue.component("benefits-slider", BenefitsSlider);

import TreatmentTracker from "./components/members/dashboard/treatment_tracker";
Vue.component("treatment-tracker", TreatmentTracker);

import ProgressTabs from "./components/members/dashboard/progress_tabs";
Vue.component("progress-tabs", ProgressTabs);

import OrdersSlider from "./components/members/dashboard/orders_slider";
Vue.component("orders-slider", OrdersSlider);

import basketUpsell from "./components/shop/basket/basket_upsell";
Vue.component("basket-upsell", basketUpsell);

require ("./site_scripts/mtc_menu.js");
require ("./site_scripts/mtc_accordions.js");
require ("./site_scripts/mtc_range_slider.js");
require ("./site_scripts/mtc_content_sidebar.js");
require ("./site_scripts/mtc_item.js");
require ("./site_scripts/mtc_featured_products.js");
require ("./site_scripts/mtc_faqs.js");
require ("./site_scripts/mtc_countdown.js");
require ("./site_scripts/mtc_mobile_search_bar.js");
require ("./site_scripts/mtc_nhs_map.js");
require ("./site_scripts/mtc_our_other_services.js");
require ("./site_scripts/mtc_timers.js");
require ("./site_scripts/mtc_testimonial_slider.js");
require ("./site_scripts/mtc_stats_banner.js");
require ("./site_scripts/mtc_sticky.js");
require ("./site_scripts/mtc_service_list.js");
require ("./site_scripts/mtc_site_wide_messages.js");
require ("./site_scripts/mtc_selling_points.js");
require ("./site_scripts/mtc_search.js");
require ("./site_scripts/mtc_search_v2.js");
require ("./site_scripts/mtc_popular_clinics.js");
require ("./site_scripts/mtc_title_banner.js");
require ("./site_scripts/mtc_information_banner.js");
require ("./site_scripts/mtc_slider.js");
require ("./site_scripts/mtc_cta_tabs.js");
require ("./site_scripts/mtc_video_tabs.js");
require ("./site_scripts/mtc_category_tabs.js");
require ("./site_scripts/mtc_sidescroller.js");
require("./site_scripts/mtc_overlay.js");


// Members verification
require ("./site_scripts/mtc_account_verify.js");


//Sidebar Functionality
require ("./site_scripts/mtc_sidebar.js");


//NHS Prescriptions
require ("./site_scripts/mtc_nhs_prescriptions.js");

//import NhsWelcomeComponent from "../../../plugins/NHS/js/components/prescriptions_welcome.vue";
//Vue.component('nhs-welcome-component', NhsWelcomeComponent);

//import NhsPrescriptionsV2 from "../../../plugins/NHS/js/components/nhs_prescriptions_v2.vue";
//Vue.component('nhs-prescriptions-v2', NhsPrescriptionsV2);

//import NhsMedications from "../../../plugins/NHS/js/components/nhs_medications.vue";
//Vue.component('nhs-medications', NhsMedications);

//import SurgeryMapSearch from "../../../plugins/NHS/js/components/gp_surgery_selection.vue";
//Vue.component('surgery-map-search', SurgeryMapSearch);

//import NhsPrescriptionExemptions from "../../../plugins/NHS/js/components/nhs_prescription_exemptions.vue";
//Vue.component('nhs-prescription-exemptions', NhsPrescriptionExemptions);

//import MultiSelectCheckbox from "../../../plugins/NHS/js/components/multi_select_checkbox.vue";
//Vue.component('multi-select-checkbox', MultiSelectCheckbox);

//import MemberAddress from "../../../plugins/NHS/js/components/member_address.vue";
//Vue.component('member-address', MemberAddress);

//import NhsPrescriptionsConfirm from "../../../plugins/NHS/js/components/nhs_prescriptions_confirm.vue";
//Vue.component('nhs-prescriptions-confirm', NhsPrescriptionsConfirm);



//Form functionality
//import SingleCheckbox from "../../../plugins/NHS/js/components/single_checkbox.vue";
//Vue.component('single-checkbox', SingleCheckbox);
//
//import AddressForm from "./components/address_form.vue";
//Vue.component('address-form', AddressForm);

import DatePicker from './components/date_picker.vue';
Vue.component('datepicker', DatePicker);

import Chosen from './components/chosen.vue';
Vue.component('chosen', Chosen);

//Bundles
//import BundleCreator from '../../../plugins/mtc_pharmacy_bundles/src/assets/js/components/bundle_creator.vue';
//Vue.component('bundle-creator', BundleCreator);

import ThemeToggle from './components/mtc_theme_toggle.vue';
Vue.component('theme-toggle', ThemeToggle);

/*
* Replacement for tagit
 */
import VueTagsInput from '@johmun/vue-tags-input';
Vue.component('vue-tags-input', VueTagsInput);

//Assessment Store
import store from './components/store/store.js';

// delivery date on checkout (T163031)
import DeliveryDate from './components/delivery_date.vue';
Vue.component('delivery-date', DeliveryDate);

$.holdReady(true);

window.vue_environment = new Vue({
    el: '#vue_environment',
    store,
    delimiters: ['[%', '%]'],
    mounted() {
        $.holdReady(false);
    }
});

require ("./script.js");
