var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "productCardLarge", class: _vm.cssClasses },
    [
      _c("div", { staticClass: "productCardLarge__grid" }, [
        _c("div", { staticClass: "productCardLarge__gridImage" }, [
          _c("section", { staticClass: "productImageSection" }, [
            _vm.slideshow
              ? _c(
                  "div",
                  { staticClass: "productCardImage" },
                  [
                    _c("product-images", {
                      attrs: {
                        images: _vm.product.images,
                        "lazy-load": true,
                        size: "thumbs",
                        slideshow: true,
                        "show-arrows": true,
                        "show-thumbs": false,
                        "allow-zoom": false,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "a",
                  {
                    staticClass: "productCardImage",
                    attrs: { href: _vm.productUrl },
                  },
                  [
                    _c("product-images", {
                      attrs: {
                        images: _vm.product.images,
                        "lazy-load": true,
                        size: "thumbs",
                        slideshow: false,
                        "show-arrows": false,
                        "show-thumbs": false,
                        "allow-zoom": false,
                      },
                    }),
                  ],
                  1
                ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "productCardLarge__gridContent" }, [
          _c("div", { staticClass: "innerContent" }, [
            _c(
              "header",
              { staticClass: "productHeader" },
              [
                _c("stock-badge", {
                  attrs: {
                    "has-stock": _vm.inStock,
                    price: _vm.fromPrice,
                    "show-price": false,
                    "currency-symbol": "£",
                    small: false,
                    icon: true,
                    bubble: true,
                    "css-class": ["productPage", "productLargeStock"],
                  },
                }),
                _vm._v(" "),
                _c("a", { attrs: { href: _vm.product.url } }, [
                  _vm.product.name
                    ? _c("h2", {
                        staticClass: "productTitle",
                        domProps: { textContent: _vm._s(_vm.product.name) },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.product.custom.description__product_card
              ? _c("div", {
                  staticClass: "itemDescription wysiwyg",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.product.custom.description__product_card
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "productLink" }, [
              _c(
                "a",
                {
                  staticClass: "button buttonOutlined",
                  attrs: {
                    href: _vm.product.url,
                    title: "View product",
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "buttonLabel" }, [
                    _vm._v("Full product details"),
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "svgIcon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "10",
                          height: "10",
                          viewBox: "0 0 10 10",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            id: "Vector",
                            d: "M10 5.48559L5.54444 9.99121L3.86667 9.99121L7.72222 6.09233L4.64936e-08 6.09233L6.08303e-08 4.89009L7.73333 4.89009L3.87778 0.991211L5.55556 0.991211L10 5.48559Z",
                            fill: "currentColor",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "productCardLarge__gridOptions" },
          [
            _c("add-to-basket", {
              attrs: {
                "ajax-add-to-basket":
                  _vm.$store.state.minibasket.productCardsQuickAdd,
                "basket-button-data": _vm.product.basket_button_data,
                "browse-page": true,
                product: _vm.product,
                "show-description": false,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }