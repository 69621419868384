import { render, staticRenderFns } from "./browse_page.vue?vue&type=template&id=5110a136"
import script from "./browse_page.vue?vue&type=script&lang=js"
export * from "./browse_page.vue?vue&type=script&lang=js"
import style0 from "./browse_page.vue?vue&type=style&index=0&id=5110a136&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home2/staoush/public_maksym/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5110a136')) {
      api.createRecord('5110a136', component.options)
    } else {
      api.reload('5110a136', component.options)
    }
    module.hot.accept("./browse_page.vue?vue&type=template&id=5110a136", function () {
      api.rerender('5110a136', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/default/js/components/shop/browse/browse_page.vue"
export default component.exports