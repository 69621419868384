var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "basketUpsellWrap", class: _vm.loaded ? "loading" : "" },
    [
      _c("div", { staticClass: "basketUpsell" }, [
        _c(
          "div",
          { staticClass: "basketUpsell__titleWrap" },
          [
            _c(
              _vm.headingType,
              { tag: "component", staticClass: "basketUpsell__title" },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.headingText) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.loaded && _vm.hasProducts(_vm.items)
          ? _c(
              "ul",
              { staticClass: "basketUpsell__list" },
              [
                _vm._l(_vm.products, function (product) {
                  return [
                    _c(
                      "li",
                      { key: "product_id_" + product.id },
                      [
                        _c("product-card-large", {
                          attrs: {
                            product: product,
                            "in-stock": product.in_stock,
                            "from-price": product.from_price,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            )
          : !_vm.loaded
          ? _c(
              "div",
              { staticClass: "basketUpsell__loading" },
              [
                _c("loading-spinner", {
                  attrs: {
                    loading: !_vm.loaded,
                    "loading-text": _vm.loading_text,
                    "show-loading-text": true,
                  },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "basketUpsell__error" }, [
              _c("p", { staticClass: "errorText" }, [
                _vm._v(_vm._s(_vm.error_text)),
              ]),
            ]),
        _vm._v(" "),
        _vm.button
          ? _c("div", { staticClass: "basketUpsell__button" }, [
              _c(
                "a",
                {
                  staticClass: "button buttonAlt",
                  attrs: { href: _vm.buttonLink },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.buttonText) +
                      "\n            "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }