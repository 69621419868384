<template>
    
  <main class="browsePage">

      <template v-if="page">
          <page-title 
                  :title="page.title" 
                  :content="page.title_content" 
                  css-class="browseTitle"
                  :brand="(typeof page.brand_data !== 'undefined' && page.brand_data !== null) ? page.brand_data : false"
          ></page-title>
          
          <template v-if="page.brand_data">
              <div class="heroAdvice" v-if="page.brand_data.additional_info !== null && page.brand_data.additional_info !== ''">
                  <div class="wrapper">
                      <div class="heroAdvice__inner">
                          <div class="heroAdvice__icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                          </div> <!-- .heroAdvice__icon -->
                          <div class="heroAdvice__content" v-html="page.brand_data.additional_info">
                              <h5>Get professional support</h5>
                              <p>Speak to a professional dermatological skincare advisor to find out what products could best help you. You can call <a href="tel:0161 503 3731">0161 503 3731</a> or email <a href="mailto:info@oushkpharmacy.com">info@oushkpharmacy.com</a> to find out more.</p>
                          </div> <!-- .heroAdvice__content -->
                      </div> <!-- .heroAdvice__inner -->
                  </div> <!-- .wrapper -->
              </div> <!-- .heroAdvice -->
          </template>
      </template>

      <div class="wrapper">
          
            <product-filter 
                  :items="products" 
                  :filters="filters"
                  :loading="loading"
                  @update-ajax="updateAjax"
           ></product-filter>
    
          <product-list 
                  :loading="loading" 
                  :products="products" 
                  :parent-class="['browseProducts']" 
                  :list-class="['itemsBrowse']"
          ></product-list>
          
          <pagination 
                  :pagination="pagination" 
                  @update-ajax="updateAjax"
          ></pagination>
          
          
          <loading-spinner
                  :loading="loading"
                  loading-text="Loading Products"
          ></loading-spinner>
    
      </div> <!-- .wrapper -->
      
  </main>
    
</template>

<script>

import productList from './product_list';
import productFilter from './product_filter';
import pagination from './pagination';
import pageTitle from '../../pages/page_title';
import axios from 'axios';

export default {
    name: "BrowsePage",
    components: {
        productFilter,
        pagination,
        pageTitle,
        productList
    },
    props: {
        setProducts: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setFilters: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setPage: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setPagination: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        }
    },
    data() {
        return {
            products: [],
            filters: [],
            page: [],
            pagination: [],
            previousURL: window.location.href,
            loading: false
        }
    },
    created() {
        this.products = this.setProducts;
        this.filters = this.setFilters;
        this.page = this.setPage;
        this.pagination = this.setPagination;

    },
    mounted() {

        const _this = this;
        
        window.onpopstate = function(e){
            if(e.state){
                _this.updateAjax(window.location.href, true);
            }
        };

    },
    computed: {
        
        
    },
    methods: {
        
        updateAjax(url, usedPopState) {
            
            if(typeof usedPopState == 'undefined') {
                usedPopState = false;
            }
            
            const _this = this;
            
            _this.loading = true;

            const title = document.getElementById('heroTitle');
            title.scrollIntoView({ behavior: 'smooth', block: 'start'});
            
            const urlWithAjax = this.enableAjaxURL(url);

            _this.previousURL = url;
            
            
            axios.get(urlWithAjax)
            .then(function (response) {
                
                const newData = response.data;


                _this.products = [];
                
                _this.$nextTick(function() {

                    _this.products = newData.products;
                    _this.filters = newData.filters;
                    _this.page = newData.page;
                    _this.pagination = newData.pagination;
                    
                    if(!usedPopState) {
                        window.history.pushState({url : url}, '', url);
                    }
                    
                    
                });
                
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(function () {
                _this.loading = false;
            });
        },

        //Work around as htaccess is breaking post requests made to the page
        enableAjaxURL(url) {

            const parts = url.split('?');
            
            let query = '';
            
            if(parts.length > 1) {
                query = '?' + parts[1] + '&ajax_request=true';
            }else{
                query = '?ajax_request=true'
            }
            
            return parts[0] + query;
            
        },
        
        hasProducts(products) {
            
            if(typeof products != 'undefined') {
                return products.length;
            }
            
            return false;
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .itemsBrowse {
        .itemsList();
    }
    
    .browsePage {
        position: relative;

        .loadingSpinner {
            position: absolute;
            top: 160px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
        }
    }
    
    .browseProducts {

        position: relative;
        transition: opacity 300ms;
        min-height: 300px;
        
        &.isLoading {
            opacity: 0.4;
            pointer-events: none;
            transition: opacity 300ms;
        }
        
    }
    
    .heroAdvice {
        position: relative;
        margin: 20px 0 0;
        
        &__inner {
            display: flex;
            flex-flow: row nowrap;
            gap: 20px;
            background: #F7F0EE;
            border-radius: 18px;
            padding: 35px;
        }
        
        &__icon {
            .rems(32);
            
            svg {
                height: 1em;
                width: auto;
            }
        }
        
        &__content {
            h5 {
                
            }
        }
    }
  


</style>
