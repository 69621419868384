<template>
    <section class="basketUpsellWrap"
             :class="(loaded) ? 'loading' : ''"
    >
        <div class="basketUpsell">
            
            <div class="basketUpsell__titleWrap">
                <component :is="headingType" 
                           class="basketUpsell__title">
                    {{ headingText }}
                </component>
            </div> <!-- .basketUpsell__titleWrap -->
            
            <ul v-if="loaded && hasProducts(items)"
                class="basketUpsell__list">
    
                <template v-for="product in products">
    
                    <li :key="'product_id_' + product.id">
    
    
                        <product-card-large
                            :product="product"
                            :in-stock="product.in_stock"
                            :from-price="product.from_price"
                        ></product-card-large>
    
                    </li>
    
                </template>
    
            </ul> <!-- .basketUpsell__list -->
            
            <div v-else-if="!loaded" class="basketUpsell__loading">
                <loading-spinner
                    :loading="!loaded"
                    :loading-text="loading_text"
                    :show-loading-text="true"
                ></loading-spinner>
            </div>  <!-- .basketUpsell__loading -->
    
            <div v-else class="basketUpsell__error">
                <p class="errorText">{{ error_text }}</p>
            </div>  <!-- .basketUpsell__error -->
    
            <div v-if="button"
                 class="basketUpsell__button">
                <a :href="buttonLink"
                   class="button buttonAlt">
                    {{ buttonText }}
                </a>
            </div>  <!-- .basketUpsell__buttons -->
        </div> <!-- .basketUpsell -->
    </section>  <!-- .basketUpsellWrap -->
</template>

<script>
export default {
    name: "basketUpsell",
    props: {
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        heading: {
            type: Boolean,
            default (rawProps) {
                return true;
            }
        },
        headingType: {
            type: String,
            default (rawProps) {
                return 'h2';
            }
        },
        headingText: {
            type: String,
            default (rawProps) {
                return 'You might also want';
            }
        },
        button: {
            type: Boolean,
            default (rawProps) {
                return false;
            }
        },
        buttonText: {
            type: String,
            default (rawProps) {
                return '';
            }
        },
        buttonLink: {
            type: String,
            default (rawProps) {
                return '';
            }
        }
    },
    data() {
        return {
            loading_text: 'Loading Products',
            error_text: 'There appears to be an issue loading these products. Try again later.',
            loaded: false,
            items: [],
        }
    },
    created() {

    },
    mounted() {
        this.getProducts();
    },
    computed: {

    },
    methods: {
        hasProducts(products) {

            if(typeof products != 'undefined') {
                return products.length;
            }

            return false;

        },
        getProducts() {
            var vm = this;
            if(vm.hasProducts(vm.products)) {
                vm.items = vm.products;
            } else {
                // inset ajax request here
            }

            if(!vm.hasProducts(vm.items)) {
                // set vm.errorText if required
            }

            this.loaded = true;
        }
    }
}
</script>

<style lang="less">
@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';

.basketUpsell {
    max-width: 1070px;
    min-width: @gutter_width;
    margin: 0 auto;
    padding: 120px 20px;
    .clearfix();

    @media @flyout {
        padding: 70px 20px;
    }

    @media @mobile {
        padding: 30px 20px 18px;
    }
    
    &Wrap {
        display: flex;
        flex-wrap: wrap;
        min-height: 80vh;
        color: @text_color;
        position: relative;
        background-color: @light_color;
        background-image: url(/sites/default/images/backgrounds/assessment-blobs.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    &__titleWrap {
        text-align: center;
    }
    
    &__title {
        margin: 0 0 29px;
    }

    &__list {
        display: flex;
        flex-flow: column nowrap;
        gap: 35px;

        @media @mobile {
            gap: 18px;
        }
        
        &:before, &:after {
            display: none;
        }
    }

    &__button {
        text-align: center;
        padding: 29px 0 0;

        .button {
            min-width: 50%;
            margin: 0;

            @media @mobile {
                width: 100%;
                min-width: unset;
            }
        }
    }

    &__loading, &__error {
        position: relative;
        text-align: center;
        margin: 40px 0;

        .errorText {
            font-weight: 700;
            color: @alert_color;
        }
    }
}
</style>
